import React, { useEffect, useState } from "react";
import { Outlet, useNavigate,useLocation  } from "react-router-dom";
import style from "./style.module.css";
import LogoUsaha from "../../Photo/logoUsaha.png";

const InventoryGym=()=>{
    const location = useLocation();
    const navigate = useNavigate();
    const [tulisanJudul,setTulisanJudul]=useState("Pengeluaran");
    const [id,setId]=useState("pengeluaran");
    // function Klik link judul
    const KlikLink=(id)=>{
setId(id);
switch(id){
    case "pengeluaran":
        setTulisanJudul("Pengeluaran");
        navigate("/gym/inventory");
        break;
        case "pemasukan":
        setTulisanJudul("Pemasukan");
        navigate("/gym/inventory/pemasukan");
        break;
        case "rekapan":
        setTulisanJudul("Rekapan");
        navigate("/gym/inventory/rekapan");
        break;
        case "cashFlow":
            setTulisanJudul("Cash Flow");
            navigate("/gym/inventory/cashflow");
            break;
default:
    break;
}}
    //
    useEffect(() => {
        const path = location.pathname.split("/").pop();
        switch (path) {
          case "pemasukan":
            setId("pemasukan");
            setTulisanJudul("Pemasukan");
            break;
          case "cashflow":
            setId("cashFlow");
            setTulisanJudul("Cash Flow");
            break;
            case "rekapan":
                setId("rekapan");
                setTulisanJudul("Rekapan");
                break;
          
            case "pengeluaran":
                setId("pengeluaran");
                setTulisanJudul("Pengeluaran");
                break;
                default:
                    break;
        }
      }, [location.pathname]);
    
    return(
<div className={style.containerAll}>
    <div className={style.divJudul}>
<div className={style.divTulisanJudul}>
    <img onClick={()=>navigate('/gym')} src={LogoUsaha}/>
    {tulisanJudul} Inventory Gym
</div>
<div className={style.divLinkJudul}>
    <div style={{borderBottom:id==="pengeluaran"?"solid 1px #2f69fd":""}} onClick={()=>KlikLink("pengeluaran")}  className={style.divLink}>Pengeluaran</div>
    <div style={{borderBottom:id==="pemasukan"?"solid 1px #2f69fd":""}} onClick={()=>KlikLink("pemasukan")}  className={style.divLink}>Pemasukan</div>
    <div style={{borderBottom:id==="cashFlow"?"solid 1px #2f69fd":""}} onClick={()=>KlikLink("cashFlow")}  className={style.divLink}>Cash Flow</div>
    <div style={{borderBottom:id==="rekapan"?"solid 1px #2f69fd":""}} onClick={()=>KlikLink("rekapan")}  className={style.divLink}>Rekapan</div>
   
</div>
    </div>

<Outlet/>
    

</div>
    )
}
export default InventoryGym;
