import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
import LogoUsaha from "../../Photo/logoUsaha.png";

const AttendanceGym = (props) => {
  const navigate = useNavigate();
  const url = props.url;
  const inputRef = useRef(null);
  const [valueInput, setValueInput] = useState("");
  const [valueHasilNama, setValueHasilNama] = useState("");
  const [valueHasilDate, setValueHasilDate] = useState("");
  const [prosesScan, setProsesScan] = useState(true);
  const [tulisanSudahScanAtas, setTulisanSudahScanAtas] = useState("");
  const [tulisanSudahScanBawah, setTulisanSudahScanBawah] = useState("");
  const [dataTrainerZumba, setDataTrainerZumba] = useState([]);
  const [jumlahAttendanceZumba, setJumlahAttendanceZumba] = useState(0);
  const [border, setBorder] = useState("");
  // Function to handle input change
  const handleInputChange = (val) => {
    setValueInput(val);
    let today = new Date();
    let year = today.getFullYear();
    let month = String(today.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    let day = String(today.getDate()).padStart(2, "0");

    let formattedDate = `${year}-${month}-${day}`;
    setValueHasilDate(formattedDate);

    // Check if the input length is 10
    if (/[^0-9]/.test(val)) {
      setValueHasilNama("Kartu Error");
      setValueInput("");
      setTimeout(() => {
        setValueHasilNama("");
      }, 1000);
    } else if (val === "1313131313" || val === "1414141414") {
      if (
        localStorage.getItem("instruktur") === null ||
        localStorage.getItem("instruktur") === ""
      ) {
        setValueInput("");
        setValueHasilNama("Pilih dahulu instrukturnya");
        setTimeout(() => {
          setValueHasilNama("");
        }, 1000);
      } else {
        fetch(url + "/?op=inputFreelanceZumba", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            "rfid=" + val + "&instruktur=" + localStorage.getItem("instruktur"),
        })
          .then((response) => response.json())
          .then((json) => {
            if (json === "tidak ada koneksi") {
              setProsesScan(false);
              setTulisanSudahScanAtas("Ups Koneksi internet tidak ada :(");
            } else {
              setProsesScan(false);
              setTulisanSudahScanAtas("Keep Healthy :)");
              setTulisanSudahScanBawah(json);
              AmbilJumlahAttendanceZumba();
            }
            setValueInput("");

            // Clear the input after 1000 ms
            setTimeout(() => {
              setProsesScan(true);
            }, 1000);
          });
      }
      /*
       */
    } else if (
      (val.length === 10 && val !== "1313131313") ||
      (val.length === 10 && val !== "1414141414")
    ) {
      localStorage.setItem("rfidVisitorZumba", val);
      fetch(url + "/?op=getNamaScanZumba", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: "rfid=" + val + "&date=" + valueHasilDate, // Assuming 'val' contains the RFID value
      })
        .then((response) => response.json())
        .then((json) => {
          if (json === "tidak ada koneksi") {
            setProsesScan(false);
            setTulisanSudahScanAtas("Ups Koneksi internet tidak ada :(");
          } else if (json === "data tidak ditemukan") {
            navigate("/zumba/attendance/memberBaru");
          } else {
            setProsesScan(false);
            setTulisanSudahScanAtas("Keep Healthy :)");
            setTulisanSudahScanBawah(json);
          }

          setValueInput("");

          // Clear the input after 1000 ms
          setTimeout(() => {
            setProsesScan(true);
          }, 1000);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  //function jika belum scan
  const BelumScan = () => {
    return (
      <div className={style.divKotakTap}>
        <input
          placeholder="Tap your card please :)"
          value={valueHasilNama}
          readOnly={true}
          className={style.inputField}
        />
      </div>
    );
  };

  //function jika sudah scan
  const SudahScan = () => {
    return (
      <div className={style.divSudahScan}>
        <div className={style.sudahScanAtas}>{tulisanSudahScanAtas}</div>
        <div className={style.sudahScanBawah}>{tulisanSudahScanBawah}</div>
      </div>
    );
  };

  //

  function AmbilJumlahAttendanceZumba() {
    let instruktur = localStorage.getItem("instruktur");
    let today = new Date();
    let year = today.getFullYear();
    let month = String(today.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    let day = String(today.getDate()).padStart(2, "0");

    let formattedDate = `${year}-${month}-${day}`;
    fetch(url + "/?op=getJumlahAttendanceZumba", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "instruktur=" + instruktur + "&date=" + formattedDate, // Assuming 'val' contains the RFID value
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setJumlahAttendanceZumba(json.filter((item)=>item.instruktur === instruktur).length);
        } else {
          setJumlahAttendanceZumba(0);
        }
      });
  }

  //
  const KlikKotakTrainer = (val) => {
    setBorder(val);
    localStorage.setItem("instruktur", val);
    AmbilJumlahAttendanceZumba();
  };
  //use effect
  useEffect(() => {
    // Initially focus the input field
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // Set an interval to refocus the input field every 100 ms
    const interval = setInterval(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    setDataTrainerZumba([
      { nama: "Zin Susi", id: "zinSusi" },
      { nama: "Zin Icha", id: "zinIcha" },
      { nama: "Aerobic", id: "aerobic" },
      { nama: "Yoga", id: "yoga" },
    ]);
    localStorage.removeItem("instruktur");
  }, []);

  return (
    <div className={style.containerAll}>
      <input
        className={style.inputBayangan}
        value={valueInput}
        onChange={(e) => handleInputChange(e.target.value)}
        ref={inputRef}
      />
      <div className={style.tulisanAttendance}>
      <img onClick={()=>navigate('/gym')} src={LogoUsaha} />Attendance Zumba</div>
      <div className={style.divKotakTrainer}>
        {dataTrainerZumba.map((val, index) => (
          <div
            id={val.id}
            style={{ border: border === val.id ? "solid 1px #2f69fd" : "" }}
            onClick={() => KlikKotakTrainer(val.id)}
            className={style.kotakTrainer}
            key={index}
          >
            {val.nama}
          </div>
        ))}

        {jumlahAttendanceZumba > 0 ? (
          <div className={style.jumlahAttendanceZumba}>
            Jumlah Attendance : {jumlahAttendanceZumba}
          </div>
        ) : (
          ""
        )}
      </div>

      {prosesScan === true ? BelumScan() : SudahScan()}
    </div>
  );
};

export default AttendanceGym;
