import React from "react";
import style from "./style.module.css";
import InputForm from "../../Component/InputForm";
import Button from "../../Component/Button";
import SpinnerForm from "../../Component/SpinnerForm";
import { useState } from "react";
import { useEffect } from "react";

const MemberPerpanjanganGym=()=>{
    const [tulisanKeterangan,setTulisanKeterangan]=useState("");
    const [tanggalStart,setTanggalStart]=useState("");
    const [tanggalExpired,setTanggalExpired]=useState("");
//function klik
const ValKirim=(val)=>{
    if (val === "kosong") {
        setTulisanKeterangan("Data tidak lengkap");
    }else{
        setTulisanKeterangan("");
        
    }
}

//function pilih spinner
const ValSpinner=()=>{
    
}

//use effect
useEffect(()=>{
    setTanggalStart(localStorage.getItem("startGymPerpanjangan"));
    setTanggalExpired(localStorage.getItem("expiredGymPerpanjangan"));
},[])

    return (
        <div className={style.containerAll}>
            <div className={style.judul}>
                Extend Member
            </div>
            <div className={style.isi}>
                <div className={style.kotakForm}>
                    <div className={style.judulForm}>
                        Perpanjang Member
                    </div>
                    <div className={style.isiForm}>
                        <div className={style.divStartExpired}>
<div className={style.divStart}>
    <div> start</div>
    <div>{tanggalStart}</div>
</div>
<div className={style.divTengah}></div>
<div className={style.divExpired}><div>expired</div>
    <div>{tanggalExpired}</div></div>
                        </div>
                        <div className={style.inputBiasa}>
                            <InputForm nama="Nama" id="namaGymPerpanjangan" />
                        </div>
                        <div className={style.inputBiasa}>
                            <InputForm nama="Alamat" id="alamatGymPerpanjangan" />
                        </div>
                        <div className={style.inputBiasa}>
                            <InputForm type="number" nama="Nomor Handphone" id="nomorGymPerpanjangan" />
                        </div>
                       
                        <div className={style.inputBiasa}>
                            <SpinnerForm nama="Waktu Member" id="waktuGymPerpanjangan" />
                        </div>
                        <div className={style.keterangan}>
                            {tulisanKeterangan}
                        </div>
                        <div className={style.divKirim}>
                            <Button ValKirim={ValKirim} borderRadius="2vw" nama="Kirim" id="kirimGymPerpanjangan" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};




export default MemberPerpanjanganGym;