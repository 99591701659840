import React, { useState, useEffect } from "react";
import InputForm from "../../Component/InputForm";
import SpinnerForm from "../../Component/SpinnerForm";
import Button from "../../Component/Button";
import style from "./style.module.css";

const MemberBaruGym = (props) => {
    const url = props.url;
    const [tulisanKeterangan, setTulisanKeterangan] = useState("");
    const [displayAfiliator, setDisplayAfiliator] = useState("notVisibleAfiliator");
    const [displayWaktu, setDisplayWaktu] = useState("notVisibleWaktu");

    // function Val Spinner
    const ValSpinner = (val) => {
        console.log(val);
        if(val ==="muncul"){
        setDisplayAfiliator("visibleAfiliator");
        setDisplayWaktu("visibleWaktu");
        }else{
            setDisplayAfiliator("notVisibleAfiliator");
        setDisplayWaktu("notVisibleWaktu");
        }
    };

    // function setelah klik kirim
    const ValKirim = (val) => {
        if (val === "kosong") {
            setTulisanKeterangan("Data tidak lengkap");
        }else{
            setTulisanKeterangan("");
            
        }
    };

    //useEffect
    useEffect(()=>{
        let nama = localStorage.getItem("namaMemberGym");
        let alamat = localStorage.getItem("alamatMemberGym");
        let nomor = localStorage.getItem("nomorMemberGym");
        let waktu = localStorage.getItem("waktuMemberGym");
        let status = localStorage.getItem("statusMemberGym");
        let namaAfiliator = localStorage.getItem("namaAfiliatorMemberGym");
        let nomorHpAfiliator = localStorage.getItem("nomorHpAfiliatorMemberGym");
        localStorage.removeItem("namaMemberGym");
        localStorage.removeItem("alamatMemberGym");
        localStorage.removeItem("nomorMemberGym");
        localStorage.removeItem("waktuMemberGym");
        localStorage.removeItem("statusMemberGym");
        localStorage.removeItem("namaAfiliatorMemberGym");
        localStorage.removeItem("nomorHpAfiliatorMemberGym");

    },[])

    return (
        <div className={style.containerAll}>
            <div className={style.judul}>
                New Member
            </div>
            <div className={style.isi}>
                <div className={style.kotakForm}>
                    <div className={style.judulForm}>
                        Buat Member
                    </div>
                    <div className={style.isiForm}>
                        <div className={style.inputBiasa}>
                            <InputForm nama="Nama" id="namaMemberGym" />
                        </div>
                        <div className={style.inputBiasa}>
                            <InputForm nama="Alamat" id="alamatMemberGym" />
                        </div>
                        <div className={style.inputBiasa}>
                            <InputForm type="number" nama="Nomor Handphone" id="nomorMemberGym" />
                        </div>
                        <div className={style.inputBiasa}>
                            <SpinnerForm ValSpinner={ValSpinner} nama="Status" id="statusMemberGym" />
                        </div>
                        <div className={style[displayAfiliator]}>
                            <InputForm nama="Afiliator" id="afiliatorMemberGym" />
                        </div>
                        <div className={style[displayWaktu]}>
                            <SpinnerForm nama="Waktu Member" id="waktuMemberGym" />
                        </div>
                        <div className={style.keterangan}>
                            {tulisanKeterangan}
                        </div>
                        <div className={style.divKirim}>
                            <Button ValKirim={ValKirim} borderRadius="2vw" nama="Kirim" id="kirimMemberGymBaru" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberBaruGym;
