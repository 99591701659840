import React from 'react';
import style from "./style.module.css";

const MenuTable = ({ items, updateCart, cart }) => {
  const handleAdd = (item) => {
    const cartItem = cart.find(i => i.name === item.name);
    const newQuantity = cartItem ? cartItem.quantity + 1 : 1;
    if (newQuantity <= item.stock) {
      updateCart(item.name, item.price, newQuantity);
    }
  };

  const handleRemove = (item) => {
    const cartItem = cart.find(i => i.name === item.name);
    if (cartItem) {
      const newQuantity = cartItem.quantity - 1;
      updateCart(item.name, item.price, newQuantity);
    }
  };

  return (
    <div className={style.containerAll}>
      <h2 className={style.judulSticky}>Menu Barang</h2>
      
      <table className={style.table}>
        <thead>
          <tr className={style.trSticky}>
            <th>Nama Barang</th>
            <th>Harga Barang</th>
            <th>Stok Barang</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.name}>
              <td>{item.name}</td>
              <td>{item.price}</td>
              <td>{item.stock}</td>
             
              <td className={style.tdQuantity}>
                <button onClick={() => handleRemove(item)}>-</button>
                <span>
                  {cart.find(i => i.name === item.name)?.quantity || 0}
                </span>
                <button onClick={() => handleAdd(item)}>+</button>
              </td>
       
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    
  );
};

export default MenuTable;
