import React from "react";
import style from "./style.module.css";
import {useNavigate} from 'react-router-dom'; 

const ButtonKotak=(props)=>{
    const navigate = useNavigate();
    const Klik=(id)=>{
        switch(id){
            case"loginGym":
            localStorage.setItem("pilihanLogin",id);
            props.ValPilihan(id);
            break;
            case"loginZumba":
            localStorage.setItem("pilihanLogin",id);
            props.ValPilihan(id);
            break;
            case"loginOwner":
            localStorage.setItem("pilihanLogin",id);
            props.ValPilihan(id);
            break;
            case "attendanceGym":
                navigate("/gym/attendance");
                break;
                case "inventoryGym":
                    navigate("/gym/inventory");
                    break;
                    case "attendanceZumba":
                        navigate("/zumba/attendance");
                        break;
                        case "inventoryZumba":
                            navigate("/zumba/inventory");
                            break;
        default:
            break;
        }
  
    }
return(
    <div onClick={()=>{
        Klik(props.id);
    }} className={style.containerAll}>
<div className={style.divGambar}>
    <img src={props.gambar}/>
</div>
<div className={style.divNama}>
    {props.nama}
</div>
    </div>
)
}
export default ButtonKotak;