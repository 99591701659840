import React from 'react';
import style from "./style.module.css";

const Receipt = ({ cart, handleCheckout }) => {
  const totalHarga = cart.reduce((total, item) => total + item.total, 0);

  return (
    <div className={style.containerAll}>
      <h2>Kuitansi Pembelian</h2>
      <div className={style.divTable}>
        {cart.length === 0 ? (
          <p>Keranjang kosong</p>
        ) : (
          <>
            <div className={style.tableContainer}>
              <table className={style.table}>
                <thead>
                  <tr className={style.trSticky}>
                    <th>Nama Barang</th>
                    <th>Harga Barang</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map(item => (
                    <tr key={item.name}>
                      <td>{item.name}</td>
                      <td>{item.price}</td>
                      <td>{item.quantity}</td>
                      <td>{item.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={style.totalSection}>
              <p>Total Harga: {totalHarga}</p>
              <button className={style.button} onClick={handleCheckout}>Checkout</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Receipt;
