import React, { useState, useEffect } from "react";
import InputForm from "../../Component/InputForm";
import SpinnerForm from "../../Component/SpinnerForm";
import Button from "../../Component/Button";
import style from "./style.module.css";

const MemberBaruGym = (props) => {
    const url = props.url;
    const [tulisanKeterangan, setTulisanKeterangan] = useState("");
    const [displayAfiliator, setDisplayAfiliator] = useState("notVisibleAfiliator");
    const [displayWaktu, setDisplayWaktu] = useState("notVisibleWaktu");

    // function Val Spinner
    const ValSpinner = (val) => {
        console.log(val);
        if(val ==="muncul"){
        setDisplayAfiliator("visibleAfiliator");
        setDisplayWaktu("visibleWaktu");
        }else{
            setDisplayAfiliator("notVisibleAfiliator");
        setDisplayWaktu("notVisibleWaktu");
        }
    };

    // function setelah klik kirim
    const ValKirim = (val) => {
        if (val === "kosong") {
            setTulisanKeterangan("Data tidak lengkap");
        }else{
            setTulisanKeterangan("");
            
        }
    };

    //useEffect
    useEffect(()=>{
    
        localStorage.removeItem("namaMemberZumba");
        localStorage.removeItem("alamatMemberZumba");
        localStorage.removeItem("nomorMemberZumba");
        localStorage.removeItem("statusMemberZumba");
    

    },[])

    return (
        <div className={style.containerAll}>
            <div className={style.judul}>
                New Member
            </div>
            <div className={style.isi}>
                <div className={style.kotakForm}>
                    <div className={style.judulForm}>
                        Buat Member
                    </div>
                    <div className={style.isiForm}>
                        <div className={style.inputBiasa}>
                            <InputForm nama="Nama" id="namaMemberZumba" />
                        </div>
                        <div className={style.inputBiasa}>
                            <InputForm nama="Alamat" id="alamatMemberZumba" />
                        </div>
                        <div className={style.inputBiasa}>
                            <InputForm type="number" nama="Nomor Handphone" id="nomorMemberZumba" />
                        </div>
                        <div className={style.inputBiasa}>
                            <SpinnerForm ValSpinner={ValSpinner} nama="Status" id="statusMemberZumba" />
                        </div>
                    
                        <div className={style.keterangan}>
                            {tulisanKeterangan}
                        </div>
                        <div className={style.divKirim}>
                            <Button ValKirim={ValKirim} borderRadius="2vw" nama="Kirim" id="kirimMemberZumbaBaru" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberBaruGym;
