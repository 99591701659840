import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import style from "./style.module.css";
import LogoOwner from "../../Photo/logoOwner.png";

const Owner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tulisanJudul, setTulisanJudul] = useState("Cash Flow");
  const [id, setId] = useState("cashFlow");
  const [divGantiAkun, setDivGantiAkun] = useState("notVisibleDivGantiAkun");
  //fucntion klik logo akun
  const KlikLogoAkun=()=>{
      setDivGantiAkun("visibleDivGantiAkun");
  }
  //fucntion klik button ganti akun
  const KlikButton=(val)=>{
      if(val==="ya"){
      navigate("/");
      localStorage.removeItem("loginSebagai");
      }else{
          setDivGantiAkun("notVisibleDivGantiAkun");
      }
  }
  // function Klik link judul
  const KlikLink = (id) => {
    setId(id);
    switch (id) {
      case "cashFlow":
        setTulisanJudul("Cash Flow");
        navigate("/owner");
        break;
      case "database":
        setTulisanJudul("Database");
        navigate("/owner/database");
        break;
      case "afiliator":
        setTulisanJudul("Afiliator");
        navigate("/owner/afiliator");
        break;
      case "pajak":
        setTulisanJudul("Pajak");
        navigate("/owner/pajak");
        break;
      default:
        break;
    }
  };
  //
  useEffect(() => {
    const path = location.pathname.split("/").pop();
    switch (path) {
      case "pajak":
        setId("pajak");
        setTulisanJudul("Pajak");
        break;
      case "cashflow":
        setId("cashFlow");
        setTulisanJudul("Cash Flow");
        break;
      case "database":
        setId("database");
        setTulisanJudul("Database");
        break;

      case "afiliator":
        setId("afiliator");
        setTulisanJudul("Afiliator");
        break;
      default:
        break;
    }
  }, [location.pathname]);

  return (
    <div className={style.containerAll}>
      <div className={style.divJudul}>
        <div className={style.divTulisanJudul}>
          <div onClick={() => KlikLogoAkun()} className={style.akun}>
            <img src={LogoOwner} />
          </div>
          <div className={style[`${divGantiAkun}`]}>
                    <div className={style["judulGantiAkun"]}>
                        Login dengan akun lain
                    </div>
                    <div className={style.pilihanGantiAkun}>
<button onClick={()=>KlikButton("ya")}>Ya</button>
<button onClick={()=>KlikButton("tidak")}>Tidak</button>
                    </div>
                </div>
          Owner {tulisanJudul}
        </div>
        <div className={style.divLinkJudul}>
          <div
            style={{
              borderBottom: id === "cashFlow" ? "solid 1px #2f69fd" : "",
            }}
            onClick={() => KlikLink("cashFlow")}
            className={style.divLink}
          >
            Cash FLow
          </div>
          <div
            style={{
              borderBottom: id === "database" ? "solid 1px #2f69fd" : "",
            }}
            onClick={() => KlikLink("database")}
            className={style.divLink}
          >
            Database
          </div>
          <div
            style={{
              borderBottom: id === "afiliator" ? "solid 1px #2f69fd" : "",
            }}
            onClick={() => KlikLink("afiliator")}
            className={style.divLink}
          >
            Afiliator
          </div>
          <div
            style={{ borderBottom: id === "pajak" ? "solid 1px #2f69fd" : "" }}
            onClick={() => KlikLink("pajak")}
            className={style.divLink}
          >
            Pajak
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
};
export default Owner;
