import React, { useState } from "react";
import { useEffect } from "react";
import style from "./style.module.css";

const OwnerDatabase = (props) => {
  const url = props.url;

  const [jumlahKaryawanGym, setJumlahKaryawanGym] = useState("");
  const [jumlahKaryawanZumba, setJumlahKaryawanZumba] = useState("");
  const [jumlahTrainerGym, setJumlahTrainerGym] = useState("");
  const [jumlahTrainerZumba, setJumlahTrainerZumba] = useState("");
  const [jumlahMemberAktifGym, setJumlahMemberAktifGym] = useState("");
  const [jumlahMemberExpiredGym, setJumlahMemberExpiredGym] = useState("");
  const [jumlahMemberGym, setJumlahMemberGym] = useState("");
  const [jumlahFreelanceGym, setJumlahFreelanceGym] = useState("");
  const [jumlahFreelanceZumba, setJumlahFreelanceZumba] = useState("");
  const [divId, setDivId] = useState("attendanceVisitor");
  const [divDatabase, setDivDatabase] = useState("karyawanDatabase");
  const [detailVisitorGym, setDetailVisitorGym] = useState([]);
  const [detailVisitorZumba, setDetailVisitorZumba] = useState([]);
  const [databaseKaryawanGym, setDatabaseKaryawanGym] = useState([]);
  const [databaseKaryawanZumba, setDatabaseKaryawanZumba] = useState([]);
  const [databaseTrainerGym, setDatabaseTrainerGym] = useState([]);
  const [databaseTrainerZumba, setDatabaseTrainerZumba] = useState([]);
  const [databaseMemberAktifGym, setDatabaseMemberAktifGym] = useState([]);
  const [databaseMemberExpiredGym, setDatabaseMemberExpiredGym] = useState([]);
  const [dataAttendanceKaryawanGym, setDataAttendanceKaryawanGym] = useState([]);
  const [dataAttendanceKaryawanZumba, setDataAttendanceKaryawanZumba] = useState([]);
  const [dataAttendanceTrainerGym, setDataAttendanceTrainerGym] = useState([]);
  const [dataAttendanceTrainerZumba, setDataAttendanceTrainerZumba] = useState([]);
  const [panjangDatabaseKaryawanGym, setPanjangDatabaseKaryawanGym] =
    useState(0);
  const [panjangDatabaseKaryawanZumba, setPanjangDatabaseKaryawanZumba] =
    useState(0);
  const [panjangDatabaseTrainerGym, setPanjangDatabaseTrainerGym] = useState(0);
  const [panjangDatabaseTrainerZumba, setPanjangDatabaseTrainerZumba] =
    useState(0);
  const [panjangDatabaseMemberAktifGym, setPanjangDatabaseMemberAktifGym] =
    useState(0);
  const [panjangDatabaseMemberExpiredGym, setPanjangDatabaseMemberExpiredGym] =
    useState(0);
  let today = new Date();
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
  let day = String(today.getDate()).padStart(2, "0");

  let formattedDate = `${year}-${month}-${day}`;
  //
  async function GetDatabaseKartuGym(val) {
    await fetch(url + "/?op=getDatabaseKartuGym")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          // karyawan
          if (json.filter((item) => item.status === "karyawan").length > 0) {
            setJumlahKaryawanGym(
              json.filter((item) => item.status === "karyawan").length
            );
            setPanjangDatabaseKaryawanGym(1);
            setDatabaseKaryawanGym(
              json.filter((item) => item.status === "karyawan")
            );
          } else {
            setJumlahKaryawanGym(0);
            setPanjangDatabaseKaryawanGym(0);
            setDatabaseKaryawanGym([]);
          }
          //trainer
          if (json.filter((item) => item.status === "trainer").length > 0) {
            setJumlahTrainerGym(
              json.filter((item) => item.status === "trainer").length
            );
            setPanjangDatabaseTrainerGym(1);
            setDatabaseTrainerGym(
              json.filter((item) => item.status === "trainer")
            );
          } else {
            setJumlahTrainerGym(0);
            setPanjangDatabaseTrainerGym(0);
            setDatabaseTrainerGym([]);
          }
          //
          if (
            json.filter(
              (item) =>
                item.status === "member" && item.expired_member >= formattedDate
            ).length > 0
          ) {
            setJumlahMemberAktifGym(
              json.filter(
                (item) =>
                  item.status === "member" &&
                  item.expired_member >= formattedDate
              ).length
            );
            setPanjangDatabaseMemberAktifGym(1);
            setDatabaseMemberAktifGym(
              json.filter(
                (item) =>
                  item.status === "member" &&
                  item.expired_member >= formattedDate
              )
            );
          } else {
            setJumlahMemberAktifGym(0);
            setPanjangDatabaseMemberAktifGym(0);
            setDatabaseMemberAktifGym([]);
          }
          if (
            json.filter(
              (item) =>
                item.status === "member" && item.expired_member < formattedDate
            ).length > 0
          ) {
            setJumlahMemberExpiredGym(
              json.filter(
                (item) =>
                  item.status === "member" &&
                  item.expired_member < formattedDate
              ).length
            );
            setPanjangDatabaseMemberExpiredGym(1);
            setDatabaseMemberExpiredGym(
              json.filter(
                (item) =>
                  item.status === "member" &&
                  item.expired_member < formattedDate
              )
            );
          } else {
            setJumlahMemberExpiredGym(0);
            setPanjangDatabaseMemberExpiredGym(0);
            setDatabaseMemberExpiredGym([]);
          }
        } else {
        }
      });
  }

  //
  async function GetDatabaseKartuZumba(val) {
    await fetch(url + "/?op=getDatabaseKartuZumba")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          //karyawan
          if (json.filter((item) => item.status === "karyawan").length > 0) {
            setJumlahKaryawanZumba(
              json.filter((item) => item.status === "karyawan").length
            );
            setPanjangDatabaseKaryawanZumba(1);
            setDatabaseKaryawanZumba(
              json.filter((item) => item.status === "karyawan")
            );
          } else {
            setJumlahKaryawanZumba(0);
            setPanjangDatabaseKaryawanZumba(0);
            setDatabaseKaryawanZumba([]);
          }
          //zumba
          if (json.filter((item) => item.status === "trainer").length > 0) {
            setJumlahTrainerZumba(
              json.filter((item) => item.status === "trainer").length
            );
            setPanjangDatabaseTrainerZumba(1);
            setDatabaseTrainerZumba(
              json.filter((item) => item.status === "trainer")
            );
          } else {
            setJumlahTrainerZumba(0);
            setPanjangDatabaseTrainerZumba(0);
            setDatabaseTrainerZumba([]);
          }
        } else {
        }
      });
  }

  //
  async function GetDataPengunjungGym() {
    await fetch(url + "/?op=getDataPengunjungGym")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          let jsonJumlahMemberGym = json.filter(
            (item) => item.status === "member"
          ).length;
          let jsonJumlahFreelanceGym = json.filter(
            (item) => item.status === "freelance"
          ).length;
          let jsonDataVisitorGym = json.filter(
            (item) => item.status === "member" || item.status === "freelance"
          );

          setJumlahMemberGym(jsonJumlahMemberGym);
          setJumlahFreelanceGym(jsonJumlahFreelanceGym);
          setDetailVisitorGym(jsonDataVisitorGym);
        } else {
        }
      });
  }
  //
  async function GetDataPengunjungZumba() {
    await fetch(url + "/?op=getDataPengunjungZumba")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          let jsonJumlahFreelanceZumba = json.filter(
            (item) => item.status === "freelance"
          ).length;
          let jsonDataVisitorZumba = json.filter(
            (item) => item.status === "freelance"
          );
          setDetailVisitorZumba(jsonDataVisitorZumba);
          setJumlahFreelanceZumba(jsonJumlahFreelanceZumba);
        } else {
        }
      });
  }

  //
  function GetDataPengunjungGymWithDate(val) {
    fetch(url + "/?op=getDataPengunjungGymWithDate", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateVisitor"),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json !== null) {
          if (json.filter((item) => item.status === "member").length > 0) {
            setJumlahMemberGym(
              json.filter((item) => item.status === "member").length
            );
          } else {
            setJumlahMemberGym(0);
          }
          if (json.filter((item) => item.status === "freelance").length > 0) {
            setJumlahFreelanceGym(
              json.filter((item) => item.status === "freelance").length
            );
          } else {
            setJumlahFreelanceGym(0);
          }
          if (
            json.filter(
              (item) => item.status === "member" || item.status === "freelance"
            ).length > 0
          ) {
            setDetailVisitorGym(
              json.filter(
                (item) =>
                  item.status === "member" || item.status === "freelance"
              )
            );
          } else {
            setDetailVisitorGym([]);
          }
        } else {
          setJumlahMemberGym(0);
          setJumlahFreelanceGym(0);
          setDetailVisitorGym([]);
        }
      });
  }
  //
  function GetDataPengunjungZumbaWithDate(val) {
    fetch(url + "/?op=getDataPengunjungZumbaWithDate", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateVisitor"),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json !== null) {
          if (json.filter((item) => item.status === "freelance").length > 0) {
            setJumlahFreelanceZumba(
              json.filter((item) => item.status === "freelance").length
            );
            setDetailVisitorZumba(
              json.filter((item) => item.status === "freelance")
            );
          } else {
            setJumlahFreelanceZumba(0);
            setDetailVisitorZumba([]);
          }
        } else {
          setJumlahFreelanceZumba(0);
          setDetailVisitorZumba([]);
        }
      });
  }
  // fungsi ubah tanggal
  const ChangeDate = (val) => {
    localStorage.setItem("dateVisitor", val);
    GetDataPengunjungGymWithDate(val);
    GetDataPengunjungZumbaWithDate(val);
  };
  // fungsi klik Div Id pilih attendance visitor atau yang lain
  const KlikDivId = (val) => {
    setDivId(val);
  };
  // fungsi klik Div Database pilih tampilan attendancenya apa
  const KlikDivDatabase = (val) => {
    setDivDatabase(val);
  };
  // fungsi jika attendance visitor

  const AttendanceVisitor = () => {
    return (
      <div className={style.containerAllAttendanceVisitor}>
        <div className={style.divAVKiri}>
          <div className={style.judulDivAVKiri}>Attendance Gym</div>
          <div className={style.isiDivAVKiri}>
            <table className={style.tableAVKiri}>
              <thead>
                <tr className={style.trStickyAVKiri}>
                  <th>Timestamp</th>

                  <th>Nama</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {detailVisitorGym.map((item, index) => (
                  <tr key={index}>
                    <td> {item.timestamp}</td>

                    <td> {item.nama}</td>
                    <td> {item.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={style.divAVKanan}>
          <div className={style.judulDivAVKiri}>Attendance Zumba</div>
          <div className={style.isiDivAVKiri}>
            <table className={style.tableAVKiri}>
              <thead>
                <tr className={style.trStickyAVKiri}>
                  <th>Timestamp </th>

                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {detailVisitorZumba.map((item, index) => (
                  <tr key={index}>
                    <td>{item.timestamp}</td>

                    <td>freelance  {item.instruktur}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
  // fungsi menampilkan detail ID dari database yang dipilih
  const DetailDI=(val)=>{
switch(val){
    case "karyawanDatabase":
        return(
        <div className={style.divDetailKaryawanDatabase}>
            <div className={style.tanggalDetailDatabase}>
                <input onChange={(e)=>ChangeDateDatabaseKaryawan(e.target.value)} type="date"/>
            </div>
            <div className={style.divAtasDetailDatabase}>
<div className={style.judulDivAtasDetailDatabase}>
Attendance Karyawan Gym
</div>
<div className={style.isiDivAtasDetailDatabase}>
<table className={style.tableDetailDatabase}>
<thead>
    <tr className={style.trSticky}>
        <th >Nama</th>
        <th >Jam Datang</th>
        <th >Jam Pulang</th>
    </tr>
</thead>
<tbody>
    {dataAttendanceKaryawanGym.map((item,index)=>(
        <tr key={index}>
<td>{item.nama}</td>
<td>{item.jam_datang}</td>
<td>{item.jam_pulang}</td>
        </tr>
    ))}
</tbody>
</table>
</div>
            </div>
            <div className={style.divBawahDetailDatabase}>
            <div className={style.judulDivAtasDetailDatabase}>
Attendance Karyawan Zumba
</div>
<div className={style.isiDivAtasDetailDatabase}>
<table className={style.tableDetailDatabase}>
<thead>
    <tr className={style.trSticky}>
        <th >Nama</th>
        <th >Jam Datang</th>
        <th >Jam Pulang</th>
    </tr>
</thead>
<tbody>
    {dataAttendanceKaryawanZumba.map((item,index)=>(
        <tr key={index}>
<td>{item.nama}</td>
<td>{item.jam_datang}</td>
<td>{item.jam_pulang}</td>
        </tr>
    ))}
</tbody>
</table>
</div>
</div>
        </div>)
        
        case "trainerDatabase":
            return(
                <div className={style.divDetailKaryawanDatabase}>
                    <div className={style.tanggalDetailDatabase}>
                        <input onChange={(e)=>ChangeDateDatabaseTrainer(e.target.value)} type="date"/>
                    </div>
                    <div className={style.divAtasDetailDatabase}>
        <div className={style.judulDivAtasDetailDatabase}>
        Attendance Trainer Gym
        </div>
        <div className={style.isiDivAtasDetailDatabase}>
        <table className={style.tableDetailDatabase}>
        <thead>
            <tr className={style.trSticky}>
            <th >Timestamp</th>
                <th >Nama</th>
            </tr>
        </thead>
        <tbody>
            {dataAttendanceTrainerGym.map((item,index)=>(
                <tr key={index}>
                    <td>{item.timestamp}</td>
        <td>{item.nama}</td>
        
 
                </tr>
            ))}
        </tbody>
        </table>
        </div>
                    </div>
                    <div className={style.divBawahDetailDatabase}>
                    <div className={style.judulDivAtasDetailDatabase}>
        Attendance Trainer Zumba
        </div>
        <div className={style.isiDivAtasDetailDatabase}>
        <table className={style.tableDetailDatabase}>
        <thead>
            <tr className={style.trSticky}>
            <th >Timestamp</th>
                <th >Nama</th>
               
             
            </tr>
        </thead>
        <tbody>
            {dataAttendanceTrainerZumba.map((item,index)=>(
                <tr key={index}>
           <td>{item.timestamp}</td>
        <td>{item.nama}</td>
                </tr>
            ))}
        </tbody>
        </table>
        </div>
        </div>
                </div>)
       
        case "memberDatabase":
     break;
      
    default:
        break;
}



  }
  // fungsi DAta id card
  const DataIdCard = () => {
    return (
      <div className={style.containerAllDataIdCard}>
        <div className={style.divDIKiri}>
          <div className={style["divDatabase"]}>
            <div onClick={()=>KlikDivDatabase("karyawanDatabase")} style={{cursor:"pointer",color: divDatabase === "karyawanDatabase" ? "#2f69fd" : "",
            borderBottom: divDatabase === "karyawanDatabase" ? "solid 1px #2f69fd" : "",}} className={style["judulDatabase"]}>Database Karyawan</div>
            <div className={style.isiDatabase}>
              <table className={style.tableDatabase}>
                <thead>
                  <tr className={style.trSticky}>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Nomor Hp</th>
                    <th>Posisi</th>
                    <th>Tgl Bergabung</th>
                  </tr>
                </thead>
                <tbody>
                  {panjangDatabaseKaryawanGym > 0
                    ? databaseKaryawanGym.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nama}</td>
                          <td>{item.alamat}</td>
                          <td>{item.nomor_hp}</td>
                          <td>Gym</td>
                          <td>{item.awal_pendaftaran}</td>
                        </tr>
                      ))
                    : ""}
                  {panjangDatabaseKaryawanZumba > 0
                    ? databaseKaryawanZumba.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nama}</td>
                          <td>{item.alamat}</td>
                          <td>{item.nomor_hp}</td>
                          <td>Zumba</td>
                          <td>{item.awal_pendaftaran}</td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
          <div className={style["divDatabase"]}>
            <div onClick={()=>KlikDivDatabase("trainerDatabase")} style={{cursor:"pointer",color: divDatabase === "trainerDatabase" ? "#2f69fd" : "",
            borderBottom: divDatabase === "trainerDatabase" ? "solid 1px #2f69fd" : ""}} className={style["judulDatabase"]}>Database Trainer</div>
            <div className={style.isiDatabase}>
              <table className={style.tableDatabase}>
                <thead>
                  <tr className={style.trSticky}>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Nomor Hp</th>
                    <th>Posisi</th>
                    <th>Tgl Bergabung</th>
                  </tr>
                </thead>
                <tbody>
                  {panjangDatabaseTrainerGym > 0
                    ? databaseTrainerGym.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nama}</td>
                          <td>{item.alamat}</td>
                          <td>{item.nomor_hp}</td>
                          <td>Gym</td>
                          <td>{item.awal_pendaftaran}</td>
                        </tr>
                      ))
                    : ""}
                  {panjangDatabaseTrainerZumba > 0
                    ? databaseTrainerZumba.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nama}</td>
                          <td>{item.alamat}</td>
                          <td>{item.nomor_hp}</td>
                          <td>Zumba</td>
                          <td>{item.awal_pendaftaran}</td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
          <div className={style["divDatabase"]}>
            <div  className={style["judulDatabase"]}>Database Member</div>
            <div className={style.isiDatabase}>
              <table className={style.tableDatabase}>
                <thead>
                  <tr className={style.trSticky}>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Nomor Hp</th>
                    <th>Tgl Bergabung</th>
                    <th>Start Member</th>
                    <th>Expired Member</th>
                  </tr>
                </thead>
                <tbody>
                  {panjangDatabaseMemberAktifGym > 0
                    ? databaseMemberAktifGym.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nama}</td>
                          <td>{item.alamat}</td>
                          <td>{item.nomor_hp}</td>
                          <td>{item.awal_pendaftaran}</td>
                          <td>{item.start_member}</td>
                          <td>{item.expired_member}</td>
                        </tr>
                      ))
                    : ""}
                  {panjangDatabaseMemberExpiredGym > 0
                    ? databaseMemberExpiredGym.map((item, index) => (
                        <tr style={{ color: "red" }} key={index}>
                          <td>{item.nama}</td>
                          <td>{item.alamat}</td>
                          <td>{item.nomor_hp}</td>
                          <td>{item.awal_pendaftaran}</td>
                          <td>{item.start_member}</td>
                          <td>{item.expired_member}</td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={style.divDIKanan}>
            {DetailDI(divDatabase)}
        </div>
      </div>
    );
  };

   // fungsi setelah memilih tanggal database karyawan
   const ChangeDateDatabaseTrainer=(val)=>{
    localStorage.setItem("dateTrainer",val);
GetDaftarHadirTrainerGym();
GetDaftarHadirTrainerZumba();
  }
  // fungsi setelah memilih tanggal database karyawan
  const ChangeDateDatabaseKaryawan=(val)=>{
    localStorage.setItem("dateKaryawan",val);
GetDaftarHadirKaryawanGym();
GetDaftarHadirKaryawanZumba();
  }

  //ambil daftar hadir trainer gym
 
  function GetDaftarHadirTrainerGym(val) {
    fetch(url + "/?op=getDaftarHadirTrainerGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateTrainer"),
    })
    .then((response)=>response.json())
    .then((json)=>{
        if(json!==null){
            setDataAttendanceTrainerGym(json);
        }else{
            setDataAttendanceTrainerGym([]); 
        }
    })
}
 //ambil daftar hadir trainer zumba
 const GetDaftarHadirTrainerZumba=()=>{
    fetch(url + "/?op=getDaftarHadirTrainerZumba", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: "date=" + localStorage.getItem("dateTrainer"),
      })
      .then((response)=>response.json())
      .then((json)=>{
          if(json!==null){
              setDataAttendanceTrainerZumba(json);
          }else{
              setDataAttendanceTrainerZumba([]); 
          }
      })
}

  //ambil daftar hadir karyawan gym
 
    function GetDaftarHadirKaryawanGym(val) {
        fetch(url + "/?op=getDaftarHadirKaryawanGym", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: "date=" + localStorage.getItem("dateKaryawan"),
        })
        .then((response)=>response.json())
        .then((json)=>{
            if(json!==null){
                setDataAttendanceKaryawanGym(json);
            }else{
                setDataAttendanceKaryawanGym([]); 
            }
        })
  }
   //ambil daftar hadir karyawan zumba
   const GetDaftarHadirKaryawanZumba=()=>{
    fetch(url + "/?op=getDaftarHadirKaryawanZumba", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: "date=" + localStorage.getItem("dateKaryawan"),
      })
      .then((response)=>response.json())
      .then((json)=>{
          if(json!==null){
              setDataAttendanceKaryawanZumba(json);
          }else{
              setDataAttendanceKaryawanZumba([]); 
          }
      })
}
  //use effect
  useEffect(() => {
    localStorage.setItem("dateKaryawan","");
    localStorage.setItem("dateTrainer","");
    GetDatabaseKartuGym();
    GetDatabaseKartuZumba();
    GetDataPengunjungGym();
    GetDataPengunjungZumba();
   GetDaftarHadirKaryawanGym();
    GetDaftarHadirKaryawanZumba();
    GetDaftarHadirTrainerGym();
    GetDaftarHadirTrainerZumba();
  }, []);

  return (
    <div className={style.containerAll}>
      <div className={style.containerKiri}>
        <div
          onClick={() => KlikDivId("dataIdCard")}
          style={{
            cursor: "pointer",
            color: divId === "dataIdCard" ? "#2f69fd" : "",
            borderBottom: divId === "dataIdCard" ? "solid 1px #2f69fd" : "",
          }}
          className={style.judulMember}
        >
          Data Id Card
        </div>
        <div className={style.divTableMember}>
          <table className={style.tableMember}>
            <thead>
              <tr>
                <th>Subjek</th>
                <th>Gym</th>
                <th>Zumba</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Karyawan</td>
                <td>{jumlahKaryawanGym}</td>
                <td>{jumlahKaryawanZumba}</td>
              </tr>
              <tr>
                <td>Trainer</td>
                <td>{jumlahTrainerGym}</td>
                <td>{jumlahTrainerZumba}</td>
              </tr>
              <tr>
                <td>Member Aktif</td>
                <td>{jumlahMemberAktifGym}</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Member Expired</td>
                <td>{jumlahMemberExpiredGym}</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          onClick={() => KlikDivId("attendanceVisitor")}
          style={{
            cursor: "pointer",
            color: divId === "attendanceVisitor" ? "#2f69fd" : "",
            borderBottom:
              divId === "attendanceVisitor" ? "solid 1px #2f69fd" : "",
          }}
          className={style.judulVisitor}
        >
          Visitor
          <div className={style.date}>
            <input type="date" onChange={(e) => ChangeDate(e.target.value)} />
          </div>
        </div>
        <div className={style.divTableMember}>
          <table className={style.tableMember}>
            <thead>
              <tr>
                <th>Subjek</th>
                <th>Gym</th>
                <th>Zumba</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Member</td>
                <td>{jumlahMemberGym}</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Freelance</td>
                <td>{jumlahFreelanceGym}</td>
                <td>{jumlahFreelanceZumba}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={style.containerKanan}>
        {divId === "attendanceVisitor" ? AttendanceVisitor() : DataIdCard()}
      </div>
    </div>
  );
};
export default OwnerDatabase;
