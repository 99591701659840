import React from "react";
import style from "./style.module.css";

const PilihanLogin=(props)=>{
    const Klik=(id)=>{
    localStorage.setItem("pilihanLogin",id);
    props.ValPilihan(id);
    }
return(
    <div onClick={()=>{
        Klik(props.id);
    }} className={style.containerAll}>
<div className={style.divGambar}>
    <img src={props.gambar}/>
</div>
<div className={style.divNama}>
    {props.nama}
</div>
    </div>
)
}
export default PilihanLogin;