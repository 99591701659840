import React from "react";
import style from "./style.module.css";

const SpinnerForm=(props)=>{
//function option select
const OptionSelect =(id)=>{
switch(id){
    case"waktuMemberGym":
    return(<>
<option>Pilih</option>
    <option>1 Bulan</option>
    <option>3 Bulan</option>
    <option>6 Bulan</option>
    <option>12 Bulan</option>
    </>
    )
    case"waktuGymPerpanjangan":
    return(<>
<option>Pilih</option>
    <option>1 Bulan</option>
    <option>3 Bulan</option>
    <option>6 Bulan</option>
    <option>12 Bulan</option>
    </>
    )
    
    case"statusMemberGym":
    return(<>
<option>Pilih</option>
    <option>member</option>
    <option>karyawan</option>
    <option>trainer</option>
    </>
    )

    case"statusMemberZumba":
    return(<>
<option>Pilih</option>
    <option>karyawan</option>
    <option>trainer</option>
    </>
    )
    
    default:
        break;
}
}

    const Change=(val)=>{
localStorage.setItem(props.id,val);
if(props.id==="statusMemberGym"){
    if(val === "member"){
        props.ValSpinner("muncul");
    }else{
 props.ValSpinner("sembunyi");
    }
}

    }
    return(
<div className={style.containerAll}>
<div> {props.nama}</div>
<input readOnly={true} />
<select onChange={(e)=>Change(e.target.value)}>
{OptionSelect(props.id)}

</select>
</div>
    )
}

export default SpinnerForm;