import React, { useState } from "react";
import { useEffect } from "react";
import style from "./style.module.css";

const RekapanZumba = (props) => {
  const url = props.url;
  const [dataBarang, setDataBarang] = useState([]);
  const [dataFreelance, setDataFreelance] = useState([]);
  const [divInput, setDivInput] = useState("notVisibleDivInput");
  const [valueNamaBarang, setValueNamaBarang] = useState("");
  const [valueHargaBarang, setValueHargaBarang] = useState("");
  const [valueQuantityBarang, setValueQuantityBarang] = useState("");
  const [totalIncomeInventory, setTotalIncomeInventory] = useState(0);
  const [totalIncomeFreelance, setTotalIncomeFreelance] = useState(0);
  const [totalOutcomeInventory, setTotalOutcomeInventory] = useState(0);
  const [jumlahDataFreelance, setJumlahDataFreelance] = useState(0);
  const [nilaiDate,setNilaiDate]=useState("");

  //
  const ChangeDatePhp=(val)=>{
  fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryZumba", {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body:
      "date=" +
      val
    
  })
  .then((response) => response.json())
  .then((json) => {
    if(json!==null){
   setDataBarang(json);

    let outcome=0
    let income=0;
let jsonIncome =  json.filter((item)=>item.keterangan === "income");
let jsonOutcome =  json.filter((item)=>item.keterangan === "outcome");
for(let i=0;i<jsonIncome.length;i++){
income=parseInt(income)+parseInt(jsonIncome[i].total)

}
for(let i=0;i<jsonOutcome.length;i++){
outcome=parseInt(outcome)+parseInt(jsonOutcome[i].total)

}

setTotalIncomeInventory(income);
setTotalOutcomeInventory(outcome);
     
      
}
else{
setDataBarang([]);
setTotalIncomeInventory(0);
setTotalOutcomeInventory(0);
}

 });
 // batas fetch baru
 fetch(url + "/?op=getAmbilDataFreelanceRekapanZumba", {
  method: "post",
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
  body:
    "date=" +
    val
  
})
.then((response) => response.json())
.then((json) => {
  if(json!==null){
 
    let filterNama = json.map((item)=>item.instruktur);
    let harga ={'zinSusi':25000,'zinIcha':30000,'aerobic':15000,'yoga':30000};
    let dataFilterUnique = [...new Set(filterNama)];
    const hasil = dataFilterUnique.map(elemen => {
     const jumlah = json.filter(item => item.instruktur === elemen).length;
     const total = jumlah * harga[elemen];
     return { nama: elemen, jumlah: jumlah, total: total };
 });
   setDataFreelance(hasil);
       
   setJumlahDataFreelance(hasil.length);
   let totalIncome=0;
   for(let i = 0;i<hasil.length;i++){
totalIncome=parseInt(totalIncome)+parseInt(hasil[i].total);
   }
   setTotalIncomeFreelance(totalIncome);
  }else{
    setJumlahDataFreelance(0);
    setTotalIncomeFreelance(0);
  }
 
});
}
  //
 async  function AmbilDataBarang(val) {
    await fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryZumba")
      .then((response) => response.json())
      .then((json) => {
        if(json!==null){
        
       setDataBarang(json);

        let outcome=0
        let income=0;
   let jsonIncome =  json.filter((item)=>item.keterangan === "income");
   let jsonOutcome =  json.filter((item)=>item.keterangan === "outcome");
   for(let i=0;i<jsonIncome.length;i++){
    income=parseInt(income)+parseInt(jsonIncome[i].total)

   }
   for(let i=0;i<jsonIncome.length;i++){
    outcome=parseInt(outcome)+parseInt(jsonOutcome[i].total)

   }

  setTotalIncomeInventory(income);
  setTotalOutcomeInventory(outcome);
         
          
   }
  else{
    setDataBarang([]);
    setTotalIncomeInventory(0);
    setTotalOutcomeInventory(0);
  }

     });
  }
  //
  async function AmbilDataFreelance(val) {
    await fetch(url + "/?op=getAmbilDataFreelanceRekapanZumba")
      .then((response) => response.json())
      .then((json) => {
        if(json!==null){
         let filterNama = json.map((item)=>item.instruktur);
         let harga ={'zinSusi':25000,'zinIcha':30000,'aerobic':15000,'yoga':30000};
         let dataFilterUnique = [...new Set(filterNama)];
         const hasil = dataFilterUnique.map(elemen => {
          const jumlah = json.filter(item => item.instruktur === elemen).length;
          const total = jumlah * harga[elemen];
          return { nama: elemen, jumlah: jumlah, total: total };
      });
        setDataFreelance(hasil);
            
        setJumlahDataFreelance(hasil.length);
        let totalIncome=0;
        for(let i = 0;i<hasil.length;i++){
totalIncome=parseInt(totalIncome)+parseInt(hasil[i].total);
        }
        setTotalIncomeFreelance(totalIncome);
       }else{
          setJumlahDataFreelance(0);
    setTotalIncomeFreelance(0);
        }
       
      });
  }
  //
  const KlikTr = (val) => {
    setDivInput("visibleDivInput");
    setValueNamaBarang(val.nama_barang);
    setValueHargaBarang(val.harga_barang);
    setValueQuantityBarang(val.stok_barang);
  };
  //
  const KlikNew = () => {
    setDivInput("visibleDivInput");
    setValueNamaBarang("");
    setValueHargaBarang("");
    setValueQuantityBarang("");
  };
  //
  const ChangeDate=(val)=>{
    setNilaiDate(val);
ChangeDatePhp(val);
  }
  //
  
  useEffect(() => {
    AmbilDataBarang();
    AmbilDataFreelance();
  }, []);
  return (
    <div className={style.containerAll}>
      <div className={style.judul}>
        <div className={style.tulisanJudul}>Rekapan Inventory Zumba</div>
       <div className={style.date}>
        <input type="date" value={nilaiDate} onChange={(e)=>ChangeDate(e.target.value)}/>
       </div>
      </div>
      
      <div className={style.divTable}>
        <table className={style.table}>
          <thead>
            <tr className={style.trSticky}>
              <th>Nama Barang</th>
              <th>Jumlah</th>
              <th>Total Harga</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            {dataBarang.map((val, index) => (
              <tr
                style={{ cursor: "pointer" }}
                onClick={() => KlikTr(val)}
                key={index}
              >
                <td> {val.nama_barang}</td>
                <td>{val.out_barang}</td>
                <td>{val.total}</td>
                <td>{val.keterangan}</td>
              </tr>
            ))}
            {jumlahDataFreelance>0?dataFreelance.map((item,index)=>(
              <tr key={index}>
              <td>{item.nama}</td>
              <td>{item.jumlah}</td>
              <td>{item.total}</td>
              <td>income</td>
            </tr>
            )):""}
          </tbody>
        </table>
      </div>
      <div className={style.divTotalRekap}>
<div>total income :{" "}{totalIncomeInventory + totalIncomeFreelance}</div>
<div>total outcome :{" "}{totalOutcomeInventory}</div>
<div>saldo :{" "}{totalIncomeInventory + totalIncomeFreelance-totalOutcomeInventory}</div>
      </div>
    </div>
  );
};
export default RekapanZumba;
