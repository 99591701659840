import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import style from "./style.module.css";

const InputForm = (props) => {
  const url = "https://wonderwoman.absensi.umart.site/gym.php";
  //const url ="https://09ab-182-2-71-244.ngrok-free.app/gym/gym.php";
  const [read, setRead] = useState(false);
  const [warna, setWarna] = useState(false);
  const [nilai, setNilai] = useState("");

  //function AmbilNama
  const AmbilNama = (val) => {
    fetch(url + "/?op=getNamaAfiliator", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "rfid=" + val,
    })
      .then((response) => response.json())
      .then((json) => {
        setWarna(false);
        setNilai(json[0]);
        localStorage.setItem("namaAfiliatorMemberGym", json[0]);
        localStorage.setItem("nomorHpAfiliatorMemberGym", json[1]);
      });
  };

  // function onchange
  const Change = (val) => {
    if (props.id === "afiliatorMemberGym") {
      localStorage.setItem(props.id, val);
      setNilai(val);
      setWarna(true);
      if (val.length === 10) {
        setRead(true);
        AmbilNama(val);
      }
    } else {
      localStorage.setItem(props.id, val.toUpperCase());
      setNilai(val.toUpperCase());
    }
  };

  //use Effect
  useEffect(()=>{
switch(props.id){
    case "namaGymPerpanjangan":
        setNilai(localStorage.getItem("namaGymPerpanjangan"));
        setRead(true);
        break;
        case "alamatGymPerpanjangan":
        setNilai(localStorage.getItem("alamatGymPerpanjangan"));
        setRead(true);
        break;
        case "nomorGymPerpanjangan":
        setNilai(localStorage.getItem("nomorHpGymPerpanjangan"));
        setRead(true);
        break;
    default:
        break;
}
  },[])

  return (
    <div className={style.containerAll}>
      <div> {props.nama}</div>
      <input
        type={props.type === "number" ? "number" : ""}
        value={nilai}
        style={{ color: warna === true ? "transparent" : "black" }}
        readOnly={read === true ? true : false}
        onChange={(e) => Change(e.target.value)}
      />
    </div>
  );
};

export default InputForm;
