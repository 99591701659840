import React, { useState, useEffect } from 'react';
import style from "./style.module.css";

import MenuTable from '../../Component/MenuTable';
import Receipt from '../../Component/Receipt';

const PengeluaranGym = (props) => {
    const url = props.url;
    const [items, setItems] = useState([]);
    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await fetch(`${url}/?op=getDataInventoryGym`);
            const data = await response.json();
            setItems(data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const updateCart = (name, price, quantity) => {
        setCart(prevCart => {
            const itemIndex = prevCart.findIndex(item => item.name === name);
            if (itemIndex >= 0) {
                const updatedCart = [...prevCart];
                if (quantity > 0) {
                    updatedCart[itemIndex] = { ...updatedCart[itemIndex], quantity, total: price * quantity };
                } else {
                    updatedCart.splice(itemIndex, 1);
                }
                return updatedCart;
            } else {
                return [...prevCart, { name, price, quantity, total: price * quantity }];
            }
        });
    };

    const handleCheckout = async () => {
        try {
            await fetch(`${url}/?op=inputPengeluaranDataInventoryGym`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cart)
            });
            // Memasukkan data transaksi ke dalam tabel history_pengeluaran_inventory_gym
        await fetch(`${url}/?op=inputHistoryPengeluaranGym`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cart)
        });
            setCart([]);
            fetchItems();
        } catch (error) {
            console.error('Error during checkout:', error);
        }
    };

    return (
        <div className={style.containerAll}>
            <div className={style.divMenuTable}>
                <MenuTable items={items} updateCart={updateCart} cart={cart} />
            </div>
            <div className={style.divReceipt}>
                <Receipt handleCheckout={handleCheckout} cart={cart} />
            </div>
        </div>
    );
};

export default PengeluaranGym;
